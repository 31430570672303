import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule, TENANT_ID } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { AuthDataAccessModule } from '@wellro/auth/data-access';
import { UtilsModule } from '@wellro/utils';
import { ConfigurationsModule } from '@wellro/configurations';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
} from '@nebular/theme';

import { environment } from '../../environments/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    ConfigurationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    UtilsModule.forRoot({ baseUrl: '/api', logLevel: environment.logLevel }),
    AuthDataAccessModule.forRoot({
      authUrl: '/auth',
      unauthorizedUrl: '/unauthorized',
    }),
    NbMenuModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbToastrModule.forRoot(),
    NbDialogModule.forRoot(),
    NbDatepickerModule.forRoot(),
  ],
  providers: [
    DecimalPipe,
    DatePipe,
    { provide: TENANT_ID, useValue: environment.tenantId },
  ],
})
export class CoreModule {}
