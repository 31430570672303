import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[wellroHasClaims]',
})
export class HasClaimsDirective {
  fallbackTemplateRef: TemplateRef<unknown>;
  subscription: Subscription;
  isMainTemplateAttached = false;
  isFallbackTemplateAttached = false;

  @Input() set wellroHasClaimsFallback(
    fallbackTemplateRef: TemplateRef<unknown>
  ) {
    this.fallbackTemplateRef = fallbackTemplateRef;
  }

  @Input() set wellroHasClaims(claims: string[]) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.auth.hasClaims(claims).subscribe((hasClaims) => {
      if (hasClaims) {
        this.dropFallbackTemplate();
        this.attachMainTemplate();
      } else {
        this.dropMainTemplate();
        this.attachFallbackTemplate();
      }
    });
  }

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private auth: AuthService
  ) {}

  private dropMainTemplate() {
    if (this.isMainTemplateAttached) {
      this.viewContainer.clear();
      this.isMainTemplateAttached = false;
    }
  }

  private attachMainTemplate() {
    if (!this.isMainTemplateAttached) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.isMainTemplateAttached = true;
    }
  }

  private dropFallbackTemplate() {
    if (this.isFallbackTemplateAttached) {
      this.viewContainer.clear();
      this.isFallbackTemplateAttached = false;
    }
  }

  private attachFallbackTemplate() {
    if (!this.isFallbackTemplateAttached && this.fallbackTemplateRef) {
      this.viewContainer.createEmbeddedView(this.fallbackTemplateRef);
      this.isFallbackTemplateAttached = true;
    }
  }
}
